<template>
    <div>
        <Pane />
        <a-card class="container">
            <a-row style="margin-bottom: 10px;">
                <a-col :span="22">
                <a-form-model layout="inline">
                    <a-form-model-item>
                    <a-select
                        @change="query"
                        placeholder="部门"
                        v-model="form.deptId"
                        show-search
                        style="width: 160px"
                        :filter-option="$selectFilterOption"
                    >
                        <a-select-option
                        v-for="item in organizationList"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.uniqueName }}</a-select-option
                        >
                    </a-select>
                    </a-form-model-item>

                    <!-- <a-form-model-item>
                        <a-select style="width: 100px;" v-model="form.year">
                            <a-select-option v-for="item in yearList" :key="item.value" :value="item.value">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item> -->

                    

                    <!-- <a-form-model-item>
                        <a-space>
                            <a-button @click="query" type="primary">查询</a-button>
                            <a-button @click="reset">重置</a-button>
                        </a-space>
                    </a-form-model-item> -->
                </a-form-model>
                </a-col>  
            </a-row>
            <a-table bordered :dataSource="tableList" :loading="loading" @change="onChange" :pagination="{
                total,
                current,
                pageSize,
                showTotal: (total) => `共 ${total} 条记录`,
            }" rowKey="id">
                    <a-table-column width="50px" align="center">
                        <template slot-scope="text, row, index">
                            <div style="width: 20px;height: 20px;background: #F0F2F5;border-radius: 50%;margin: auto;">{{ index + 1 }}</div>
                            <!-- <img v-if="index == 0" src="@/assets/top1.png" style="width: 28px;height: 28px">
                            <img v-else-if="index == 1" src="@/assets/top2.png" style="width: 28px;height: 28px">
                            <img v-else-if="index == 2" src="@/assets/top3.png" style="width: 28px;height: 28px">
                            <div v-else style="width: 20px;height: 20px;background: #F0F2F5;border-radius: 50%;margin: auto;">{{ index + 1 }}</div> -->
                        </template>
                    </a-table-column>
                    <a-table-column key="userName" title="姓名" data-index="userName"></a-table-column>
                    <a-table-column key="professional" title="专业" data-index="employee.professional"></a-table-column>
                    <a-table-column key="amount" title="产值">
                        <template slot-scope="text, record, index">
                            {{ text.amount }}万元
                        </template>
                    </a-table-column>
                    <a-table-column key="days" title="当月出差天数(含驻场)" width="200px">
                        <template slot-scope="text, record, index">
                            {{ text.businessOutDays }} <span v-if="text.residentDays">({{ text.residentDays }})</span>
                        </template>
                    </a-table-column>
            </a-table>
        </a-card>
    </div>
</template>
<script>
import request from "@/api/request";
import { mapGetters } from "vuex";
import organization from "@/mixins/organization";
function fetchList(params) {
    return request({
        url: "project-service/dsContract/staff",
        params: params
    });
}

export default {
    mixins: [organization],
    computed: {
        ...mapGetters("setting", ["findDataDict"]),
        ...mapGetters("auth", ["isHr"]),
        sex() {
        return this.findDataDict("sex");
        },
        employeeStatusList() {
        return this.findDataDict("employeeStatus");
        },
        lists() {
        return this.findDataDict("talentMap");
        },
        positionList() {
        return (
            this.lists.find((item) => item.value === "talentMap_post").children || []
        );
        },
    },
    data() {
        return {
            yearList: [],
            form: {
                deptId: '',
                year: ''
            },
            now: 0,
            current: 1,
            pageSize: 10,
            total: 0,
            loading: false,
            total: 0,
            tableList: []
        };
    },
    mounted() {
        const { query } = this.$route;
        const { id,year } = query || {};
        this.form.deptId = id
        const now = new Date().getFullYear()
        for (let index = 2021; index <= now; index++) {
            this.yearList.push(
                {
                    name: index,
                    value: index
                }
            )
        }
        this.form.year = year
        this.getList()
    },
    methods: {
        query() {
            this.current = 1;
            this.getList();
        },
        reset() {
            this.form = {};
            this.getList();
        },
        getList(){
            this.loading = true
            fetchList({deptId: this.form.deptId,pageNum:this.current,pageSize:this.pageSize,year:this.form.year}).then(res => {
                this.tableList = res.list
                this.total = res.totalSize
            }).finally(() => {
                this.loading = false
            })
        },
        onChange(pagination) {
            this.current = pagination.current;
            this.pageSize = pagination.pageSize;
            this.getList()
        },
        showDialog() {
            this.getList();
        }
    }
}
</script>
<style>
.selectNoBorder .ant-select-selection{
  border: none;background: none;color: rgba(0, 0, 0, 0.65);
}
.selectNoBorder .ant-select-arrow{
  display: none;
}
.selectNoBorder .ant-select-selection__rendered{
  margin: 0;
}
</style>